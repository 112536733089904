<template>
    <div class="app-container">
        <div class="content-title">政策详情</div>
        <div class="con-body" >
            <el-form ref="form" :model="policyInfoDo" :rules="rules" label-width="120px">
                <el-form-item label="渠道" prop="channelNo">
                    <el-select v-model="policyInfoDo.channelNo" placeholder="请选择">
                        <el-option v-for="item in acqChannelList" :key="item.channelNo" :label="item.name"
                            :value="item.channelNo"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="政策名称" prop="policyName">
                    <el-input v-model="policyInfoDo.policyName" style="width:400px"></el-input>
                </el-form-item>
                <el-form-item label="政策说明">
                    <el-input type="textarea" v-model="policyInfoDo.policyRemark" style="width:400px"></el-input>
                </el-form-item>
                <el-form-item label="商户费率" required>
                    <table class="rate-table" style="width:100%">
                        <tr>
                            <th style="width:210px">支付类型</th>
                            <th style="width:120px">卡类型</th>
                            <th style="width:160px">T0费率(%)</th>
                            <th style="width:200px">T0费率范围(%)</th>
                            <th style="width:160px">T1费率(%)</th>
                            <th style="width:200px">T1费率范围(%)</th>
                            <th style="width:160px">T1封顶(元)</th>
                            <th style="width:200px">T1封顶范围(元)</th>
                            <th style="width:160px">提现费(元)</th>
                            <th style="width:200px">提现费范围(元)</th>
                        </tr>
                        <tr v-for="item in policyInfoRateDoList" :key="item.id">
                            <td>{{item.payTypeCode | payType}}</td>
                            <td>{{item.cardType | formateCardType}}</td>
                            <td><el-input v-model="item.t0Rate" v-numberInt></el-input></td>
                            <td><el-input v-model="item.t0MinRate"  v-numberInt></el-input>-<el-input v-model="item.t0MaxRate"  v-numberInt></el-input></td>
                            <td><el-input v-model="item.t1Rate"  v-numberInt></el-input></td>
                            <td><el-input v-model="item.t1MinRate"  v-numberInt></el-input>-<el-input v-model="item.t1MaxRate"  v-numberInt></el-input></td>
                            <td>
                                <template v-if="item.payTypeCode=='POS'&&item.cardType=='2'">
                                    封顶<el-input v-model="item.t1TopFee"  v-numberInt></el-input>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <template v-if="item.payTypeCode=='POS'&&item.cardType=='2'">
                                    <el-input v-model="item.t1MinTopFee"  v-numberInt></el-input>-<el-input v-model="item.t1MaxTopFee"  v-numberInt></el-input>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <template  v-if="item.payTypeCode=='POS'&&item.cardType=='1'">
                                    <el-input v-model="item.cashFee" v-numberInt></el-input>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <template v-if="item.payTypeCode=='POS'&&item.cardType=='1'">
                                    <el-input v-model="item.minCashFee" v-numberInt></el-input>-<el-input v-model="item.maxCashFee" v-numberInt></el-input>
                                </template>
                                <template v-else>-</template>
                            </td>
                        </tr>
                    </table>
                </el-form-item>
                <el-form-item label="成本设定" required>
                    <table class="rate-table" style="width:100%">
                        <tr>
                            <th style="width:210px">支付类型</th>
                            <th style="width:130px">卡类型</th>
                            <th style="width:260px">T1成本</th>
                            <th style="width:160px">T0成本</th>
                            <th style="width:200px">提现费成本</th>
                            <th style="width:260px">机构最高成本（T1）</th>
                            <th style="width:200px">机构最高成本（T0）</th>
                        </tr>
                        <tr v-for="item in policyInfoCostDoList" :key="item.id">
                            <td>{{item.payTypeCode | payType}}</td>
                            <td>{{item.cardType | formateCardType}}</td>
                            <td><span class="rate-input">
                                <el-input v-model="item.t1Rate" v-numberInt:3></el-input></span>%
                                <template v-if="item.payTypeCode == 'POS' &&item.cardType=='2'">
                                    (封顶<span class="rate-input"><el-input v-model="item.t1TopFee" v-numberInt:3></el-input></span>元)
                                </template>
                            </td>
                            <td><span class="rate-input"><el-input v-model="item.t0Rate" v-numberInt:3></el-input></span>%</td>
                            <td>
                                <template v-if="item.payTypeCode == 'POS' &&item.cardType=='1'">
                                    <span class="rate-input"><el-input v-model="item.cashFee" v-numberInt:3></el-input></span>元
                                </template>
                                <template v-else>-</template>
                                </td>
                            <td><span class="rate-input"><el-input v-model="item.t1MaxRate" v-numberInt:3></el-input></span>%
                                <template v-if="item.payTypeCode == 'POS' &&item.cardType=='2'">
                                    (封顶<span class="rate-input"><el-input v-model="item.t1MaxTopFee" v-numberInt:3></el-input></span>元)
                                </template>
                            </td>
                            <td><span class="rate-input"><el-input v-model="item.t0MaxRate" v-numberInt:3></el-input></span>%</td>
                        </tr>
                        
                    </table>
                </el-form-item>
                <el-col style="text-align:center; padding:20px 0">
                    <el-button type="primary" @click="onSubmit" :loading="loading">确定</el-button>
                </el-col>
                </el-form>
        </div>
    </div>
</template>
<script>
import {PolicyApi,TerminalApi} from '@/api';
export default {
    data() {
        return {
            policyInfoDo:'',
            policyInfoRateDoList:'',
            policyInfoCostDoList:'',
            rules:{
                policyName:[{ required: true, message: '请输入政策名称', trigger: 'blur' }],
                channelNo:[{ required: true, message: '请选择渠道', trigger: 'blur' }]
            },
            loading: false,
            acqChannelList:[]
        }
    },
    created(){
        TerminalApi.acqChannel.listAllChannel().then(res=>{
            this.acqChannelList=res.data;
        });
        if(this.$route.query.policyNo){
            let policyNo = this.$route.query.policyNo
             PolicyApi.policyInfo.detail(policyNo)
                .then(res=>{
                if(res.success){
                    this.policyInfoDo = res.data.policyInfoDo
                    this.policyInfoRateDoList = res.data.policyInfoRateDoList
                    this.policyInfoCostDoList = res.data.policyInfoCostDoList
                }
                })
        }
    },
    methods: {
       
        onSubmit(){
            this.$refs.form.validate((valid) => {
            if (valid) {
                this.loading = true
                let params = {
                    policyInfoDo: this.policyInfoDo,
                    policyInfoRateDoList: this.policyInfoRateDoList,
                    policyInfoCostDoList: this.policyInfoCostDoList
                }
                PolicyApi.policyInfo.edit(this.$route.query.policyNo,params)
                    .then(res=>{
                        this.loading = false
                        if(res.success){
                            this.$message("修改成功！")
                            this.$router.push({
                                name:'ratePolicy'
                            })
                        }
                    })
            } else {
                return false;
            }
            });
            // let result = PolicyApi.save(this.rateFrom)
        }
    },
}
</script>